import React from 'react';
import styles from "./BannerDeck.module.css"
import qrCode from "../../Assets/david-emrich-X1Hozg__MiA-unsplash.jpg";
import testImage3 from "../../Assets/indrajeet-nishad-KWRqQhmZsCY-unsplash.jpg";
import testImage2 from "../../Assets/maksym--f5697b9kMc-unsplash.jpg";
import testImage from "../../Assets/kate-laine-gKkGQwnVvkc-unsplash.jpg";
import ImageOnlyBanner from "../Card/ImageOnlyBanner";

class BannerDeck extends React.Component{

    checkOrientation(){
        return window.innerWidth / window.innerHeight > 1;
    }
    render() {
        return(
            <div className={styles.deck}>
                <div className={styles.deckContent}>
                    <ImageOnlyBanner image={qrCode}/>
                    <ImageOnlyBanner image={testImage3}/>
                    <ImageOnlyBanner image={testImage2}/>
                    <ImageOnlyBanner image={qrCode}/>
                    {/*{this.checkOrientation() ? <ImageOnlyBanner image={testImage} showImage={true}/> : <ImageOnlyBanner image={testImage} showImage={false}/>}*/}
                    <ImageOnlyBanner image={testImage} />
                </div>
            </div>
        );
    }
}

export default BannerDeck;