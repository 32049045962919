import React from 'react';
import styles from "./WhatsAppCard.module.css"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faWhatsapp} from "@fortawesome/free-brands-svg-icons";
import qrCode from "../../Assets/qrcode.png"
class WhatsAppCard extends React.Component{
    render() {
        return(
            <React.Fragment>
                <div className={styles.padding}>
                    <div className={styles.col}>
                        <div className={styles.row}>
                            <a href="https://wa.me/message/734H6XTDK7JGL1">
                                <FontAwesomeIcon icon={faWhatsapp} size={"10x"} className={styles.waColour}/>
                            </a>
                        </div>
                        <div className={styles.row}>
                            <img src={qrCode} alt="QR-code" className={styles.qrCode}/>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default WhatsAppCard;