import React from 'react';
import styles from './Contact.module.css';
import WhatsAppCard from "../Components/Card/WhatsAppCard";

class Contact extends React.Component{
    render() {
        return(
            <React.Fragment>
                <div className={styles.padding}>
                    <div className={styles.innerrow}>
                        <div className={styles.innerCol}>
                            <div>
                                <h1 className={styles.contactText2}>Contact Us</h1>
                                <div className={styles.contactText}>
                                    We're on WhatsApp! Feel free to contact us, or browse our catalogue.
                                </div>
                            </div>

                        </div>

                        <div className={styles.innerCol}>
                            <div className={styles.paddingWA}>
                                <WhatsAppCard/>
                            </div>
                        </div>
                    </div>
                </div>





                {/*<div className={styles.innerrow}>*/}
                {/*    <h1>Contact</h1>*/}
                {/*</div>*/}


                {/*<div className={styles.innerrow2}>*/}

                {/*    <div className={styles.contactText}>To come in contact with us, and view pricing of our catalogue, view our WhatsApp!</div>*/}
                {/*    <div className={styles.innerCol}>*/}
                {/*        <div className={styles.innerrow2}>*/}
                {/*        <FontAwesomeIcon icon={faWhatsapp} size={"10x"}/>*/}
                {/*        <img src={qrCode} alt="QR-code" className={styles.qrCode}/>*/}
                {/*        </div>*/}
                {/*    </div>*/}


                {/*</div>*/}




            </React.Fragment>
        );
    }
}

export default Contact;