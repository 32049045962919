import React from 'react';
import styles from "./ImageOnlyCard.module.css"

class ImageOnlyBanner extends React.Component{
    render() {
        return(
            <div className={styles.bannerImage}>
                <img src={this.props.image} alt={"img"} className={styles.bannerImage2}/>
            </div>
        );
    }
}

export default ImageOnlyBanner;