import React from 'react';
import styles from './Navbar.module.css';
import { NavLink } from 'react-router-dom'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBars, faXmark} from "@fortawesome/free-solid-svg-icons";



class Navbar extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            buttonToggleOpen: false
        }
    }

    burgerClick = () => {
        this.setState({buttonToggleOpen: !this.state.buttonToggleOpen})
    }

    render() {
        return(
            <nav className={styles.navbar}>

                    <li className={styles.title}>
                        {/*<img src={pdIcon} className={styles.brandicon}/>*/}
                        BrandBakery
                    </li>



                {/*<div className={styles.navbarlinks}>*/}
                {/*    <ul className={this.state.buttonToggleOpen ? null : styles.navbarUloff}>*/}
                {/*        <li>*/}
                {/*            <NavLink to="/" className={styles.testButton}>About</NavLink>*/}
                {/*        </li>*/}
                {/*        <li>*/}
                {/*            <NavLink to="/about" className={styles.testButton}>Events</NavLink>*/}
                {/*        </li>*/}
                {/*        <li>*/}
                {/*            <NavLink to="/about" className={styles.testButton}>Contact</NavLink>*/}
                {/*        </li>*/}
                {/*    </ul>*/}
                {/*</div>*/}
                {/*<FontAwesomeIcon icon={this.state.buttonToggleOpen ? faXmark : faBars} className={styles.burger} onClick={this.burgerClick}/>*/}
            </nav>
        );
    }
}

export default Navbar;