import './App.css';
import {BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./Pages/Home";
// import About from "./Pages/About";
import React from 'react';
import Footer from "./Components/Footer/Footer";

function App() {
  return (
    <React.Fragment>
        <Router>
            {/*<Navbar/>*/}
            <Routes>
                <Route exact path="/" element={<Home/>} />
                {/*<Route path="/about" element={<About/>} />*/}
                {/*<Route path="/menumin" component={MenuMin} />*/}
                {/*<Route path="/menufull" component={MenuFull} />*/}
                {/*<Route path="/about" component={About} />*/}
                {/*<Route path="/contact" component={Contact} />*/}
                {/*<Route path="/careers" component={Careers} />*/}
                {/*<Route component={NoMatch} />*/}
            </Routes>
            <Footer/>
        </Router>

    </React.Fragment>
  );
}

export default App;
