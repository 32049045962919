import React from 'react';
import styles from "./Footer.module.css"

class Footer extends React.Component{
    date = new Date();
    render() {
        return(
            <div className={styles.footer}>
                    Handmade with 💜 in Cape Town. © {this.date.getFullYear()} BrandBakery
            </div>
        );
    }
}

export default Footer;