import React from 'react';
import styles from './Home.module.css';

import Contact from "./Contact";

import bbLogoVert from "../Assets/bbVert2.PNG"
import Navbar from "../Components/Navbar/Navbar";
import BannerDeck from "../Components/CardDeck/BannerDeck";
class Home extends React.Component{
    render() {
        return(
            <React.Fragment>

                <div className={styles.test}>
                    {/*<Navbar/>*/}


                    <div className={styles.subpages}>
                        <div className={styles.jumbotron}>
                            <div className={styles.middlediv}>
                                <div className={styles.col}>
                                    <img src={bbLogoVert} alt={""} className={styles.brandImageMobile}/>
                                    <div className={styles.row}>

                                        {/*<div className={styles.brandingText}>Brand</div>*/}
                                        {/*<div className={styles.brandingTextSecondary}>Bakery</div>*/}
                                        <div className={styles.introText}>
                                            <div className={styles.brandingText}>Looking for a</div>
                                            <span className={styles.brandingTextSecondary}>brand specialist?</span>
                                            <div className={styles.secondaryText}>" A marketing professional who designs & manages an organisation's brand "</div>
                                        </div>
                                        <img src={bbLogoVert} alt={""} className={styles.brandImage}/>
                                    </div>


                                </div>
                            </div>
                        </div>
                        <BannerDeck/>
                        <br/>
                        {/*<h1>Our clients</h1>*/}
                        {/*<br/>*/}
                        {/*<br/>*/}
                        {/*<br/>*/}
                        {/*<br/>*/}
                        {/*<CollageDeck/>*/}
                        <Contact/>
                    </div>

                </div>

            </React.Fragment>
        );
    }
}

export default Home;